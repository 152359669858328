<template>
	<header id="header" />

	<!-- Nav -->
	<nav id="nav">
		<ul>
			<li>
				<router-link :class="{ active: is_active[0] }" to="/account/#user-info">Utilisateur</router-link>
			</li>
			<li>
				<router-link :class="{ active: is_active[1] }" to="/account/#update-password">Mot de passe</router-link>
			</li>
			<li>
				<router-link :class="{ active: is_active[2] }" to="/account/#subscription">Abonnement</router-link>
			</li>
			<li>
				<router-link :class="{ active: is_active[3] }" to="/account/#delete">Suppression du compte</router-link>
			</li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main" class="page-account">
		<section id="user-info" class="main" v-show="this.is_active[0]">
			<v-form>
				<v-container>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Nom :" required variant="solo-filled"
								v-model="user_info.last_name" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Prénom :" required variant="solo-filled"
								v-model="user_info.first_name" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field label="Email :" readonly required variant="solo-filled"
								v-model="user_info.email" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Raison sociale :" required variant="solo-filled"
								v-model="user_info.company_name" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field label="Numéro de téléphone :" readonly required variant="solo-filled"
								v-model="user_info.phone_number" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field label="Api key :" readonly required variant="solo-filled" v-model="api_key" />
						</v-col>
					</v-row>
					<v-row>
						<v-spacer />
						<v-btn @click="go_alfred()" size="large" text="Ouvrir Alfred" variant="flat" />
						<v-spacer />
						<v-btn @click="go_strip()" size="large" text="Portail Stripe" variant="flat" />
						<v-spacer />
					</v-row>
				</v-container>
			</v-form>
		</section>

		<section id="subscription" class="main" v-show="this.is_active[2]">
			<stripe-pricing-table pricing-table-id="prctbl_1QUVxiGNFYzim8vI4sMsE1kh" publishable-key="pk_live_51NuIOkGNFYzim8vIyLACDMSe3usU8N9rIlHt42cqTlCksBGoOJKEi2ikU6QJXbZ5M5q5d97y18mZTrYiMqIp71Ys00PFY7CsUW" />
		</section>

		<section id="delete" class="main" v-show="this.is_active[3]">
			<v-container>
				<v-row>
					Attention&nbsp;: assurez-vous de ne pas voir d'abonnement actif avant de supprimer votre compte.<br />
				</v-row>
				<v-row>
					<v-spacer />
						<v-btn @click="show_delete_dialog()" size="large" text="Supprimer votre compte" variant="flat" />
					<v-spacer />
				</v-row>
			</v-container>
		</section>
	</div>

	<v-dialog v-model="dialog.delete.show" max-width="640px">
		<v-card title="Confirmation de la suppression de votre compte">
			<v-card-text>
				Voulez-vous vraiment supprimer votre compte ?
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn @click="delete_account()" color="purple" size="large" text="Supprimer" variant="flat" v-show="!dialog.delete.in_progress" />
				<v-btn color="purple" disabled size="large" text="Suppression en cours..." variant="flat" v-show="dialog.delete.in_progress" />
				<v-btn @click="cancel_delete_account()" color="purple" size="large" text="Annuler" variant="outlined" />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style>
div#main.page-account .main {
	border-top: none;
	padding-top: 2em;
}

#alert {
	margin-bottom: 1em;
}

#delete, #update-password {
	margin-left: auto;
	margin-right: auto;
	width: 60%;
}
</style>

<script>
	import Api from '@/Api.js'

	export default {
		beforeRouteUpdate(to) {
			this.is_active[0] = to.fullPath == "/account/#user-info" || to.hash == ""
			this.is_active[1] = to.fullPath == "/account/#update-password"
			this.is_active[2] = to.fullPath == "/account/#subscription"
			this.is_active[3] = to.fullPath == "/account/#delete"
		},
		created() {
			let api = Api.get_instance()
			this.connected = api.has_token()

			api.do_get_user_info()
				.then((response) => {
					this.api_key = response.api_key
					this.user_info.company_name = response.metadata.company_name
					this.user_info.email = response.email
					this.user_info.first_name = response.metadata.first_name
					this.user_info.last_name = response.metadata.last_name
					this.user_info.phone_number = response.metadata.phone_number
				})
		},
		data() {
			return {
				api_key: null,
				alert: {
					color: null,
					message: null,
					show: false,
					type: null
				},
				dialog: {
					delete: {
						in_progress: false,
						show: false
					}
				},
				password: {
					in_progress: false,
					new: null,
					old: null,
					rules: [
						value => value && value.length >= 6 ? true : "Le mot de passe doit faire au moins 6 caractères"
					],
					show: {
						new: false,
						old: false
					},
					valid: false
				},
				user_info: {
					company_name: null,
					email: null,
					first_name: null,
					last_name: null,
					phone_number: null
				},
				is_active: [true, false, false, false]
			}
		},
		methods: {
			cancel_delete_account() {
				this.dialog.delete.show = false
			},
			delete_account() {
				this.dialog.delete.in_progress = true

				let api = Api.get_instance()
				api.do_delete_account()
					.catch(() => {
						this.show_error('Erreur lors de la suppression du compte')
					})
					.finally(() => {
						this.dialog.delete.in_progress = false
					})
			},
			go_alfred() {
				this.$router.push({name: 'message'})
			},
			go_strip() {
				window.open('https://billing.stripe.com/p/login/test_3csbLQ3ow64q4wwaEE', '__blank').focus()
			},
			show_delete_dialog() {
				this.dialog.delete.in_progress = false
				this.dialog.delete.show = true
			},
			show_error(msg) {
				this.alert.color = 'red'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'error'
			},
			show_success(msg) {
				this.alert.color = 'green'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'success'
			},
			update_password() {
				this.password.in_progress = true

				window.setTimeout(() => {
					let api = Api.get_instance()
					api.do_check_password(this.user_info.email, this.password.old)
						.then(() => {
							api.do_update_password(this.password.new)
								.then(() => {
									this.password.old = this.password.new
									this.password.new = null
									this.show_success('Mot de passe modifié')
								})
								.catch(() => {
									this.show_error('Erreur lors de la modification du mot de passe')
								})
								.finally(() => {
									this.password.in_progress = false
								})
						})
						.catch(() => {
							this.show_error('Ancien mot de passe incorrecte')
							this.password.in_progress = false
						})
				}, 250)
			}
		},
		mounted() {
			let js = document.createElement('script')
			js.setAttribute('async', '')
			js.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js')
			document.querySelector('#header').appendChild(js)

			this.is_active[0] = this.$route.fullPath == "/account/#user-info" || this.$route.hash == ""
			this.is_active[1] = this.$route.fullPath == "/account/#update-password"
			this.is_active[2] = this.$route.fullPath == "/account/#subscription"
			this.is_active[3] = this.$route.fullPath == "/account/#delete"
		},
		name: 'AccountView'
	}
</script>
